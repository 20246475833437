.contShopLeftss {
  width: 252px;
  margin-top: 10px;
  margin-right: 20px;
  .recommend {
    width: 252px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    & > div {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 6px;
      }
    }

    .rng-leftp1 {
      width: 100%;
      height: 36px;
      padding: 0 15px;
      color: #636363;
      font-weight: bold;
      font-size: 14px;
      line-height: 36px;
      background: #f7f7f7;
    }
    .proRank {
      background: #fff;
    }
    .store {
      padding: 10px 10px 0;
      .top {
        padding: 6px;
        background-color: #fff;
      }
      .count {
        width: 40%;
        text-align: center;
      }
    }
    .others {
      padding-left: 6px;
      .other {
        display: flex;
        padding: 12px 0 0;
        &:last-child {
          padding-bottom: 12px;
          border-bottom: 1px dashed #fff;
        }
        .label {
          display: inline-block;
          width: 42px;
        }
        .img {
          position: relative;
          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            transform: translate(-50%, -50%);
          }
        }
      }
      .copy {
        display: inline;
        width: 14px;
        height: 14px;
        margin-top: 2px;
        background: url(../../../assets/icon/copy.png) no-repeat 0 0/100%;
      }
    }
    .opre {
      justify-content: space-around;
      padding: 16px 0;
      .line {
        margin-top: 0;
      }
      .storeicon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        background: url(../../../assets/icon/store.png) no-repeat 0 0/100%;
      }
    }
    .proRankTop {
      // color: #FA6402;
      // line-height: 47px;
      // height: 47px;
      padding: 8px 20px;
      font-weight: bold;
      cursor: pointer;
      & > span {
        // padding-left: 17px;
        // background: url(../../../assets/icon/rankIco.png) no-repeat 0 14px;
        i {
          margin-left: 6px;
        }
      }
      code {
        float: right;
        margin-right: 25px;
      }
      .chgArrowFx {
        transform: rotate(-90deg);
        transition: all 0.15s linear;
      }
      .chgArrowFx1 {
        margin-top: 7px;
        transition: all 0.15s linear;
      }
      .active {
        color: var(--main-color) !important;
      }
    }
    ul {
      overflow: hidden;
      background: #f8f8f8;
      code {
        float: right;
        margin-right: 25px;
      }
      .li {
        position: relative;
        margin: 4px 0;
        // height: 40px;
        padding: 4px 0;
        padding-right: 20px;
        // line-height: 40px;
        padding-left: 25px;
        color: #585858;
        cursor: pointer;
        &:before {
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-right: 14px;
          vertical-align: middle;
          background: #d8d8d8;
          border-radius: 50%;
          content: "";
        }
        .active {
          color: var(--main-color) !important;
        }
      }
    }
    .proMoreRank {
      height: 47px;
      margin-top: 6px;
      margin-bottom: 15px;
      padding-left: 38px;
      color: #636363;
      font-weight: bold;
      line-height: 47px;
      background: linear-gradient(180deg, #f6f6f6 0%, #ececec 100%);
      cursor: pointer;
      code {
        float: right;
        margin-right: 30px;
        color: #585858;
        font-size: 16px;
      }
    }
  }
  .tuijianshangpin {
    height: 325px;
    margin-top: 8px;
    text-align: center;
    border: 1px solid #f4f4f4;
    .topSp {
      position: relative;
      width: 220px;
      height: 220px;
      margin: 0 auto;
      overflow: hidden;
      .t-nm-f {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25px;
        padding: 0 5px;
        overflow: hidden;
        color: #fff;
        line-height: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: #00000090;
      }
    }
    .moy-pric {
      margin-top: 4px;
      padding-right: 10px;
      color: #ee3f41;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
  }
}

@primary-color: #f0423f;