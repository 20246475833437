.new_home-module9 {
  .mySwiper {
    width: 1200px;
    margin-bottom: 10px;
    .swiper-slide {
      width: 100% !important;
      // height: 481px;
      overflow: hidden;
    }

    .mySwiper-item {
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      padding: 16px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .box-top {
      width: 1168px;
      height: 120px;
      margin-bottom: 16px;
      overflow: hidden;
      border-radius: 4px;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .box-items {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .box-item {
        min-height: 0;
        overflow: hidden;

        .images {
          position: relative;
          width: 280px;
          height: 240px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &:hover {
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 9;
              display: block;
              width: 100%;
              height: 100%;
              background: #0003;
              content: "";
            }
          }
        }
      }
    }

    .swiper-pagination {
      bottom: 6px;
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }
    .swiper-pagination-bullet-active {
      width: 9px;
      height: 6px;
      border-radius: 3px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background-color: #333;
      border-radius: 50%;
      opacity: 0;
      &::after {
        color: #fff;
        font-size: 14px;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_left.png) no-repeat
          13px center / 10px 20px;
        content: "";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_right.png) no-repeat
          16px center / 10px 20px;
        content: "";
      }
    }
  }
}

@primary-color: #f0423f;