.search1{
    margin-left: 50px;
    .search-input{
        border: none;
        height: 50px;
        align-items: center;
        .input{
            height: 100%;
            width: 580px;
            background-color: #fff;
            padding-left: 10px;
            border: 2px solid var(--main-color);
            border-radius: 10px 0 0 10px;
        }
        .searchAll{
            width: 140px;
            height: 50px;
            border: none;
            border-radius: 0;
            color: #fff;
            background-color: var(--main-color);
            border-radius: 0 10px 10px 0;
            font-size: 18px;
        }
        .searchOur{
            width: 140px;
            height: 50px;
            border: 2px solid var(--main-color);
            color: var(--main-color);
            background-color: #fefafa;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 7px;
            margin-left: 10px;
            border-radius: 10px;
        }
    }
}
@primary-color: #f0423f;