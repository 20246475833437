.new_home-module5 {
  .module5-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    .module5-right-item {
      position: relative;
      width: 233px;
      height: 348px;
      overflow: hidden;
      background: #ffffff;
      border-bottom: 1px solid var(--main-color);
      border-radius: 4px;
      .store_code {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        display: flex;
        display: none;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top: 60px;
        background-color: #0007;
        & > img {
          width: 160px;
          height: 160px;
        }
      }
      &:hover {
        .store_code {
          display: flex;
        }
      }
      img {
        width: 233px;
        height: 280px;
        object-fit: cover;
      }
      .template-info {
        .store-name {
          display: inline-block;
          height: 34px;
          margin-left: -10px;
          padding: 0 16px 0 24px;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
          background-color: var(--main-color);
          border-radius: 4px 4px 0 4px;
          box-shadow: 4px 0px 4px 0px rgba(177, 37, 37, 0.1);
          transform: skewX(15deg);
          & > span {
            display: inline-block;
            transform: skewX(-15deg);
          }
        }
        .goods-price {
          margin-right: 16px;
          color: var(--main-color);
          font-weight: 500;
          font-weight: 700;
          font-size: 16px;
          font-family: Arial, Helvetica, sans-serif;
          line-height: 18px;
          .money {
            font-weight: normal;
            font-size: 12px;
          }
        }
        .template-name {
          margin: 8px 16px;
          color: #333;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@primary-color: #f0423f;