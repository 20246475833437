.new_home-swiper-banner {
  width: 1200px;
  height: 120px;
  margin: 8px auto;
  .swiper-slide {
    width: 100% !important;
    height: 120px;
    overflow: hidden;
    .image {
      width: 100%;
      height: 120px;
      overflow: hidden;
    }
  }
}

@primary-color: #f0423f;