.new_home-page-nav {
  height: 44px;
  margin-top: -2px;
  line-height: 44px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;

  .page-navs {
    display: flex;
    align-items: center;
    .plate-class {
      width: 24px;
      position: relative;
      padding: 0 0 0 4px !important;
      & > img {
        width: 18.4px;
        height: 18.4px;
        margin-top: -4px;
      }
      .class_box {
        position: absolute;
        top: 44px;
        left: -1px;
        z-index: 9;
        display: none;
      }
      &:hover {
        .class_box {
          display: block;
        }
      }
      &::before{
        display: none;
      }
    }
    .page-nav-more {
      position: relative;
      padding: 0 16px;
      color: var(--main-color);
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      cursor: pointer;
      .page-nav-more-extend {
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 9;
        display: none;
        min-width: 122px;
        padding: 20px 24px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.13);
        .page-nav-more-extend-item {
          width: auto;
          margin-bottom: 14px;
          color: #000000;
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
          cursor: pointer;
          &:hover {
            color: var(--main-color);
          }
          & > span {
            position: relative;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .skip-icon {
            position: absolute;
            top: -24px;
            left: 50%;
            z-index: 9;
          }
          .skip-hot-icon {
            position: absolute;
            top: -8px;
            right: 4px;
            padding: 2px 2px;
            color: #fff;
            font-size: 10px;
            line-height: 1;
            white-space: nowrap;
            text-align: center;
            background-color: var(--main-color);
            border-radius: 6px 8px 8px 0;
            transform: translateX(100%) scale(1);
            & > span {
              display: block;
              font-weight: 400;
              transform: scale(0.7);
            }
          }
        }
      }
      &:hover {
        .page-nav-more-extend {
          display: block;
        }
      }
    }
    .page-nav {
      position: relative;
      margin-right: 8px;
      padding: 0 16px;
      color: #111;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      cursor: pointer;
      &::before {
        position: absolute;
        bottom: 2px;
        left: 50%;
        width: 0;
        height: 4px;
        background-color: var(--main-color);
        border-radius: 2px;
        transform: translateX(-50%);
        transition: all 0.25s linear;
        content: "";
      }
      .count {
        position: absolute;
        top: -4px;
        right: 22px;
        z-index: 9;
        padding: 3px 5px;
        color: #fff;
        font-weight: normal;
        font-size: 10px;
        line-height: 1;
        white-space: nowrap;
        text-align: center;
        background-color: var(--main-color);
        border-radius: 6px 8px 8px 0;
        transform: translateX(100%);
      }
      @keyframes jump1 {
        0% {
          top: -12px;
        }
        100% {
          top: -8px;
        }
      }
      .skip-icon {
        position: absolute;
        top: -24px;
        left: 50%;
        z-index: 9;
      }
      @keyframes jumpicon {
        0% {
          top: -24px;
        }
        100% {
          top: -20px;
        }
      }
      &:hover {
        color: var(--main-color) !important;
        font-weight: 700;
        &::before {
          width: 34px;
          transition: all 0.25s linear;
          content: "";
        }
      }
      .skip-hot-icon {
        right: 22px;
        font-size: 10px;
        border-radius: 6px 8px 8px 0;
        transform: translateX(100%) scale(1);
      }
    }
    .page-nav.active {
      position: relative;
      color: var(--main-color) !important;
      font-weight: 700;
      &::before {
        position: absolute;
        bottom: 2px;
        left: 50%;
        width: 34px;
        height: 4px;
        background-color: var(--main-color);
        border-radius: 2px;
        transform: translateX(-50%);
        content: "";
      }
    }
    .other-active {
      padding: 0 26px;
      color: #fff;
      background-color: var(--main-color);
      border-radius: 6px 6px 0 0;

      &:hover {
        color: #fff !important;
      }
    }
  }
}

@primary-color: #f0423f;