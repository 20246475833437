.new_home-module6 {
  .module6-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .module6-right-item {
      width: 294px;
      height: 338px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 4px;
      border-bottom: 1px solid var(--main-color);

      .template-info {
        padding-top: 10px;
        .store-name {
          display: inline-block;
          height: 34px;
          margin-left: -10px;
          padding: 0 16px 0 24px;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
          background-color: var(--main-color);
          border-radius: 4px 4px 0 4px;
          box-shadow: 4px 0px 4px 0px rgba(177, 37, 37, 0.1);
          transform: skewX(15deg);
          margin-right: 16px;
          & > span {
            display: inline-block;
            transform: skewX(-15deg);
          }
        }
        .template-name {
          flex: 1;
          color: #333;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-right: 12px;
        }
      }
    }
  }
}

@primary-color: #f0423f;