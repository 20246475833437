.wealth-affix {
  position: fixed;
  top: 25%;
  right: 2px;
  z-index: 1000;
  width: 72px;
  height: 60.5px;
  background: url("/assets/wealth/wealth_fix_icon1.png") no-repeat 0 / 100%;
  transform: translateY(-60px);
  .wealth-count {
    position: absolute;
    top: 0;
    right: 12px;
    width: 12px;
    height: 12px;
    background: #ff5100;
    border-radius: 50%;
  }
  .wealth-count1 {
    top: -2px;
    right: 8px;
    width: 16px;
    height: 16px;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
  }
  .wealth-raffer-count {
    position: absolute;
    top: -36px;
    right: 10px;
    width: 126px;
    height: 46px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background: url(/assets/wealth/wealth_fix_icon10.png) no-repeat center / 100%;
    padding-top: 6px;
    line-height: 20px;
    z-index: -2;
  }
  .wealth-affix-content {
    position: absolute;
    top: -86px;
    right: 60px;
    z-index: -1;
    width: 0;
    padding: 10px 0;
    overflow: hidden;
    transition: all 0.25s linear;

    .content {
      width: 120px;
      padding: 30px 0 12px;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
      .wealth-login {
        margin-bottom: 16px;
        font-size: 12px;
      }
      .wealth-item {
        margin-bottom: 16px;
        color: #333333;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        white-space: nowrap;
        cursor: pointer;
        .wealth_icon {
          display: inline-block;
          width: 25px;
          height: 26px;
          background: url("/assets/new_home/fix_icon1.png") no-repeat 0 0 / 100%;
        }
      }
      .wealth-point {
        display: inline-block;
        width: 86px;
        height: 54px;
        margin-bottom: 16px;
        padding: 10px 0;
        border: 1px solid #ff5100;
        border-radius: 14px 14px 14px 14px;
        .point-count {
          color: #ff5100;
          font-weight: bold;
          font-size: 12px;
          line-height: 17px;
        }
        .point-line {
          width: 22px;
          height: 0px;
          margin: 3px auto 1px;
          border-bottom: 1px solid #ffcaaf;
        }
        .point-text {
          color: #333333;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
        }
      }

      .wealth-btn {
        display: inline-block;
        width: 86px;
        height: 28px;
        margin-bottom: 16px;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        text-align: center;
        background: linear-gradient(90deg, #ff9361 0%, #ff5100 100%);
        border-radius: 14px;
        cursor: pointer;
      }
    }
  }
  &:hover {
    .wealth-affix-content {
      width: 130px;
      padding-right: 10px;
    }
  }
}

.new_home-rightAffix {
  position: fixed;
  top: 25%;
  right: 12px;
  z-index: 1000;
  width: 50px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 0 #f5f5f5;
  .item {
    position: relative;
    padding: 12px 0 6px;
    color: #666;
    font-weight: 400;
    font-size: 12px;
    font-family: PingFang SC;
    line-height: 18px;
    text-align: center;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 4px 4px;
    }
    .item-box {
      position: absolute;
      top: -6px;
      right: 60px;
      z-index: 9;
      width: 0;
      padding: 6px 0;
      overflow: hidden;
      transition: all 0.25s linear;
    }
    .title {
      margin-top: -4px;
    }
    .car-count {
      margin: 0 3px;
      padding: 0 1px 0 2px;
      color: #666666;
      font-weight: 400;
      font-size: 12px;
      font-family: PingFang SC;
      text-align: center;
      border: 1px solid #999999;
      border-radius: 8px;
    }
    .item-content {
      width: 120px;
      padding: 16px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05);
      cursor: initial;
      .kuang {
        padding: 0 16px 16px;
        border-bottom: 1px dashed #e4e4e4;
      }
      .title {
        margin-top: 20px;
        color: #333333;
        font-weight: bold;
        font-size: 12px;
        font-family: PingFang SC;
        &:first-child {
          margin-top: 0;
        }
      }
      .qqwangwang {
        font-size: 10px;
      }
      .lsitem {
        margin-left: 12px;
        color: #666666;
        font-weight: 400;
        font-size: 12px;
        font-family: PingFang SC;
        line-height: 1.6;
        text-align: left;
      }
      .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 29px;
        color: #f45f53;
        text-align: center;
        background: #ffffff;
        border: 1px solid #f45f53;
        border-radius: 2px;
      }
      .orders {
        margin-top: 16px;
        .order {
          font-size: 12px;
          cursor: pointer;
          .status {
            margin: 8px 0;
            color: #666666;
          }
          .count {
            color: var(--main-color);
          }
        }
        .msg {
          margin: 8px 0;
          color: #999999;
          font-weight: 400;
          font-size: 12px;
          font-family: PingFang SC;
        }
      }
    }
    &:hover {
      color: #fff;
      .item-box {
        width: 130px;
        padding: 6px;
        overflow: hidden;
        color: #333;
      }
    }
    .code-box {
      width: 88px;
      height: 88px;
      margin-bottom: 6px;
      padding: 7px;
      border: 1px solid #e4e4e4;
    }
    .code {
      width: 74px;
      height: 74px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .custom {
    .custom-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: url(/assets/new_home/fix_icon2.png) no-repeat 1px 0 / 52px;
    }
    &:hover {
      background-color: var(--main-color);
      .custom-icon {
        background-position: -27px 0;
      }
    }
  }
  .member {
    .custom-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: url(/assets/new_home/fix_icon2.png) no-repeat 1px -27px / 52px;
    }
    &:hover {
      background-color: var(--main-color);
      .custom-icon {
        background-position: -27px -27px;
      }
    }
  }
  .buycar {
    .custom-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: url(/assets/new_home/fix_icon2.png) no-repeat 1px -54px / 52px;
    }
    &:hover {
      background-color: var(--main-color);
      .custom-icon {
        background-position: -27px -54px;
      }
    }
  }
  .code {
    .custom-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: url(/assets/new_home/fix_icon2.png) no-repeat 1px -81px / 52px;
    }
    &:hover {
      background-color: var(--main-color);
      .custom-icon {
        background-position: -27px -81px;
      }
    }
  }
}

.new_home-backTop {
  position: fixed;
  right: 12px;
  bottom: 20px;
  z-index: 999;
  width: 50px;
  padding: 6px 0;
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  cursor: pointer;
  .title {
    margin-top: 2px;
  }

  .custom-icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url(/assets/new_home/fix_icon2.png) no-repeat 1px -108px / 52px;
  }
  &:hover {
    color: #fff;
    background-color: var(--main-color);
    .custom-icon {
      background-position: -27px -108px;
    }
  }
}

@primary-color: #f0423f;