.h5{
    width: 100vw;
    height: 100vh;
    background: url(../../assets/imgs/h5bg.png) 0 0;
    background-size: 100% auto;
    position: relative;
    .open{
        position: absolute;
        top: 65vh;
        left: 50%;
        transform: translate(-50%);
        background-color: #fff;
        width: 70vw;
        height: 6vh;
        text-align: center;
        line-height: 6vh;
        color: #e5272b;
        font-weight: 700;
    }
    .down{
        position: absolute;
        top: 75vh;
        left: 50%;
        transform: translate(-50%);
        background-color: #fff;
        width: 70vw;
        height: 6vh;
        text-align: center;
        line-height: 6vh;
        font-weight: 700;
    }
    .code{
        position: absolute;
        top: 68vh;
        left: 50%;
        transform: translate(-50%);
        width: 70vw;
        height: 6vh;
        text-align: center;
        font-weight: 700;
        color: #fff;
    }
    .pri_name{
        position: absolute;
        top: 95vh;
        left: 50%;
        transform: translate(-50%);
        width: 70vw;
        text-align: center;
        color: #fff;
    }
}
@primary-color: #f0423f;