.new_home-module11 {
  .mySwiper {
    width: 1200px;
    .swiper-slide {
      height: 932px;
      width: 100% !important;
      overflow: hidden;
      .mySwiper-item {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: #f5f5f5;
      }
      .box-items {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        .box-item {
          min-height: 0;
          overflow: hidden;
          background-color: #fff;
          border-radius: 4px;

          .template-info {
            padding: 12px 16px;
          }
          .images {
            width: 233px;
            height: 233px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .template-name {
            margin-bottom: 4px;
            color: #333333;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }

          .store_name {
            color: #333333;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          .goods-price {
            color: var(--main-color);
            font-weight: 500;
            font-weight: 700;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 18px;
            .money {
              font-weight: normal;
              font-size: 12px;
            }
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background-color: #333;
      border-radius: 50%;
      opacity: 0;
      &::after {
        color: #fff;
        font-size: 14px;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_left.png) no-repeat
          13px center / 10px 20px;
        content: "";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_right.png) no-repeat
          16px center / 10px 20px;
        content: "";
      }
    }
  }

  .spots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 10px 0;
    background-color: #fff;
    .spot {
      // width: 155px;
      padding: 0 16px;
      height: 38px;
      margin-right: 24px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
    .spot_active {
      color: #fff;
      background-color: var(--main-color);
      border-color: var(--main-color);
    }
  }
}

@primary-color: #f0423f;