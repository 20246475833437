.new_home-nav {
  width: 100%;
  height: 36px;
  color: #999999;
  font-size: 12px;
  line-height: 36px;
  background-color: #f5f5f5;
  .container {
    width: 1200px;
  }
  .mobile {
    .font {
      margin-left: 2px;
    }
    &:hover {
      .font {
        color: var(--main-color);
      }
    }
  }

  .nav-item {
    position: relative;
    .more-down {
      position: absolute;
      top: 36px;
      left: -10px;
      z-index: 1000;
      display: none;
      padding: 4px 0;
      background-color: #fff;
      border: 1px solid #cccccc;
      li {
        width: 80px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        &:hover {
          background: var(--main-bg-color);
        }
      }
    }
    .mobileDown {
      position: absolute;
      top: 36px;
      right: -8px;
      z-index: 1;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #aaa;
      border-radius: 4px;
      &::after {
        position: absolute;
        top: -6px;
        right: 38px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-top: 1px solid #aaa;
        border-left: 1px solid #aaa;
        border-radius: 3px 0 0 0;
        transform: rotate(45deg);
        content: "";
      }

      .mobileDown-li {
        display: block;
      }
      .mobileDown-li1 {
        display: none;
        color: #333333;
        font-size: 10px;
        line-height: 12px;
        & > div {
          margin-top: 0;
          padding-bottom: 2px;
          text-align: center;
        }
      }
    }
    .mobileDown1 {
      position: absolute;
      top: 36px;
      right: -24px;
      z-index: 1000;
      display: none;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #aaa;
      border-radius: 4px;
      &::after {
        position: absolute;
        top: -6px;
        right: 52px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-top: 1px solid #aaa;
        border-left: 1px solid #aaa;
        border-radius: 3px 0 0 0;
        transform: rotate(45deg);
        content: "";
      }
    }
    &:hover {
      .more-down {
        display: block;
      }
      .mobileDown {
        display: flex;
      }
      .mobileDown-li {
        display: none;
      }
      .mobileDown-li1 {
        display: block;
      }
      .mobileDown1 {
        display: block !important;
      }
    }
  }
}

.notice{
  .swiper-container, .swiper-slide{
    height: 36px !important;
  }
}

@primary-color: #f0423f;