.new_home-class-box {
  position: relative;
  width: 230px;
  height: 669px;
  color: #000;
  background-color: #fff;
  & > ul {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 6px;
    &::-webkit-scrollbar {
      display: block;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    } /* 滚动条的滑轨背景颜色 */

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    } /* 滑块颜色 */

    &::-webkit-scrollbar-button {
      display: none;
      background-color: #f1f1f1;
    } /* 滑轨两头的监听按钮颜色 */

    &::-webkit-scrollbar-corner {
      background-color: black;
    } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
  }
  .info {
    position: relative;
    height: 60.8px;
    padding: 10px 14px 11px 16px;
    overflow: hidden;
    background-color: #fff;
    &::after {
      position: absolute;
      bottom: 0;
      left: 14px;
      width: calc(100% - 28px);
      height: 0;
      border-bottom: 1px solid #f5f5f5;
      content: "";
    }
    &:last-child {
      &::after {
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 28px);
        height: 0;
        border-bottom: none;
        content: "";
      }
    }
    &:hover {
      background-color: #f3f2f2;
    }
    .title {
      width: 100%;
      margin-bottom: 6px;
      color: #333333;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
  }
  ol {
    li {
      padding-right: 10px;
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      &:hover {
        color: var(--main-color);
      }
    }
  }
  .new_home-good-expansion {
    position: absolute;
    top: 0;
    left: 230px;
    z-index: 9;
    width: 478px;
    height: 100%;
    padding: 24px;
    overflow: hidden;
    background-color: #fff;
    border: 2px solid var(--main-color);
    border-radius: 2px;
    transition: all 0.25s linear;
    .choose-class {
      overflow: hidden;
    }
    .ul {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & > ul > li {
        margin-bottom: 24px;
        .title {
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }
    .title {
      margin-bottom: 8px;
      padding: 8px 0;
      color: #333333;
      font-size: 13px;
      line-height: 15px;
    }
    ol {
      li {
        margin-top: 4px;
        margin-bottom: 6px;
        padding-right: 24px;
        color: #999;
        &:hover {
          color: var(--main-color);
        }
      }
    }
    .image {
      overflow: hidden;
      border-radius: 4px;
    }
    .ant-image {
      margin-right: 10px;
    }
    .look-more {
      float: right;
      width: 56px;
      height: 140px;
      padding: 38px 20px 0;
      text-align: center;
      vertical-align: top;
      background: url(https://img.hznzcn.com/index_hz/morHot.png) no-repeat 18px
        16px;
      border: 1px solid rgb(223, 222, 222);
    }
    .new-goods {
      width: 100%;
      min-height: 207px;
      margin-top: 16px;
      padding: 12px 12px 2px;
      background: #f5f5f5;
      border-radius: 4px;
      .new-goods-title {
        margin-bottom: 6px;
        color: #333333;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
      }
      .new-goods-item:nth-child(2n) {
        margin-left: 8px;
      }
      .item-top {
        display: flex;
        margin-bottom: 8px;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        .item-top-shop-name {
          width: 68px;
          height: 22px;
          padding: 0 10px;
          line-height: 22px;
          text-align: center;
          background-color: var(--main-color);
        }
        .item-top-good-name {
          flex: 1;
          height: 22px;
          padding: 0 10px;
          line-height: 22px;
          text-align: center;
          background-color: #333333;
        }
      }
      .item-goods {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr;

        .goods {
          width: 61px;
          height: 61px;
          cursor: pointer;
          
          &:first-child {
            width: 126px;
            height: 130px;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
          }
        }
      }
    }
  }
}

@primary-color: #f0423f;