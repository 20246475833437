.new_home-module10 {
  .module10-top{
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }
  .module10-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    .module10-right-item {
      width: 188px;
      height: 188px;
      overflow: hidden;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #E5E5E5;
      cursor: pointer;
      position: relative;
      &:hover{
        &::after{
          content: '';
          position: absolute;
          width: 188px;
          height: 188px;
          left: 0;
          top: 0;
          background: #0003;
          z-index: 9;
          display: block;
        }
      }
      & > img {
        width: 188px;
        height: 188px;
        object-fit: cover;
      }
    }
  }
}

@primary-color: #f0423f;