.new_home-module8 {
  .module8-right {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    .module8-right-item {
      position: relative;
      width: 596px;
      height: 362px;
      padding: 16px;
      background-color: #fff;
      border-radius: 4px;
      &:hover {
        .template-items {
          display: grid;
        }
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .template-infos {
        position: absolute;
        bottom: 16px;
        left: 16px;
        width: calc(100% - 32px);
        height: 44px;
        line-height: 44px;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        &:hover {
          .template-items {
            display: grid;
          }
        }
        .store_name {
          margin-right: 20px;
          padding: 0 20px;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          background: var(--main-color);
        }
        .template-name {
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
        }
        .right_icon {
          margin-right: 16px;
          margin-left: 40px;
          color: #fff;
          font-size: 16px;
        }
      }

      .template-items {
        position: absolute;
        bottom: 44px;
        left: 0;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        padding: 16px;
        background: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid #e4e4e4;
        display: none;
        .template-item {
          overflow: hidden;
          border: 1px solid transparent;
          &:hover{
            border-color: var(--main-color);
          }
          & > img {
            width: 170px;
            height: 150px;
            object-fit: cover;
          }
        }
        .template-info {
          padding: 7px 10px;
          background: #ffffff;
        }
        .goods-price {
          color: var(--main-color);
          font-weight: 500;
          font-weight: 700;
          font-size: 16px;
          font-family: Arial, Helvetica, sans-serif;
          line-height: 18px;
          .money {
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@primary-color: #f0423f;