.new_home-module2 {
  .module2-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .module2-right-item {
      position: relative;
      width: 294px;
      height: 354px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 4px;
      transition: all 0.25s linear;
      &:hover {
        border-radius: 4px;
        transform: scale(1.02);
        transition: all 0.25s linear;
      }
      .template-info {
        position: absolute;
        bottom: 13px;
        left: 13px;
        width: 268px;
        height: 70px;
        padding: 13px 12px;
        text-align: center;
        background: #ffffffaa;
        border-radius: 10px;
        z-index: 9;

        .store-name {
          color: #333333;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
        .template-name {
          margin-top: 4px;
          color: #333333;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}

@primary-color: #f0423f;