.new_home-leftAffix {
  position: absolute;
  top: 852px;
  right: 50%;
  z-index: 999;
  width: 50px;
  margin-right: 614px;
  padding: 0;
  overflow: initial;
  line-height: 1.5;
  background-color: #fff;
  border-radius: 6px;
  .ant-anchor-fixed {
    overflow: initial;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 2px;
    z-index: -1;
    width: 46px;
    height: 56px;
    background: url(/assets/new_imgs/logo_ip.png) no-repeat center top / 100%;
    transition: all 0.1s linear;
    content: "";
  }
  .ant-anchor {
    padding: 0;
    .ant-anchor-ink {
      &:before {
        display: none;
      }
    }
    .ant-anchor-link {
      width: 50px;
      padding: 0;
      overflow: hidden;
      text-align: center;
      background-color: #fff;
      &:nth-child(2) {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
      .ant-anchor-link-title {
        padding: 10px;
        color: #333333;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.4;
        white-space: normal;
        &:hover {
          color: #fff;
          font-weight: 700;
          background-color: var(--main-color);
        }
      }
    }
    .ant-anchor-link-title-active {
      color: #fff !important;
      font-weight: 700;
      background-color: var(--main-color);
      .ant-anchor-link-title {
        color: #fff;
      }
    }
  }
}
.Affix-fix {
  &:after {
    top: -40px;
  }
}

@primary-color: #f0423f;