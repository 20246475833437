.new_home-module1 {
  .module1-top {
    display: flex;
    .module1-left {
      width: 252px;
      margin-right: 8px;
      & > div:first-child{
        margin-bottom: 8px;
      }
    }
  }
  .module1-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    .module1-right-item {
      width: 222px;
      height: 242px;
      background: #ffffff;
      border-radius: 4px;
      overflow: hidden;
      &:hover{
        transform: scale(1.05);
        transition: all .35s linear;
      }
      img {
        width: 222px;
        height: 182px;
        object-fit: cover;
      }
      .template-info {
        padding: 10px 12px;
        .store-name {
          color: #333333;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        .template-name {
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@primary-color: #f0423f;