.Privatebanner{
    color: #000;
    width: 185px;
    position: relative;
    background-color: #fff;
    &>ul{
        height: 521px;
        background-color: #fff;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: block;
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        } /* 滚动条的滑轨背景颜色 */

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1; 
        } /* 滑块颜色 */

        &::-webkit-scrollbar-button {
            background-color: #f1f1f1;
            display: none;
        } /* 滑轨两头的监听按钮颜色 */

        &::-webkit-scrollbar-corner {
            background-color: black;
        } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
    }
    .info{
        background-color: #fff;
        // padding-top: 4px;
        &:hover{
            background-color: #ffedee;
        }
        .title{
            width: 100%;
            // height: 34px;
            // line-height: 34px;
            height: 45px;
            padding: 0 10px;
            line-height: 1;
            .flex-center{
                margin-right: 10px;
                text-align: left;
            }
        }
    }
    ol{
        li{
            padding-right: 10px;
            margin-bottom: 6px;
        }
    }
    .good-expansion{
        position: absolute;
        top: 0;
        left: 184px;
        width: 200px;
        background-color: #fff;
        border: 1px solid #e5272b;
        border-top: none;
        transition: all 0.25s linear;
        overflow: hidden;
        padding: 0 20px;
        padding-top: 10px;
        z-index: 9999999;
        // border-left: none;
        .choose-class{
            overflow: hidden;
        }
        ul{
            // height: 563px;
            height: 520px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .title{
            height: 30px;
            margin-top: 10px;
        }
        ol{
            li{
                padding-right: 20px;
                margin-top: 8px;
                margin-bottom: 4px;
            }
        }
        .image{
            border-radius: 4px;
            overflow: hidden;
        }
        .ant-image{
            margin-right: 10px;
        }
        .look-more{
            float: right;
            border: 1px solid rgb(223, 222, 222);
            height: 140px;
            width: 56px;
            vertical-align: top;
            text-align: center;
            padding: 38px 20px 0;
            background: url(https://img.hznzcn.com/index_hz/morHot.png)no-repeat 18px 16px;
        }
    }
}
@primary-color: #f0423f;