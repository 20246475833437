.new_home-header {
  padding: 8px 0 10px 0;
  background-color: #fff;
  .container {
    width: 1200px;
  }

  .new_home-search {
    width: 862px;
    .serchByImg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 172px;
      height: 38px;
      height: 50px;
      margin-left: 18px;
      color: #fff;
      font-size: 18px;
      background-color: var(--main-color);
      border-radius: 10px;
    }
    .search-top {
      margin-left: 8px;
      & > span {
        padding: 4px 12px 0;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
      .active {
        color: #fff;
        background-color: var(--main-color);
        &:hover {
          color: #fff;
          background-color: var(--main-color);
        }
      }
    }
    .search-input {
      position: relative;
      height: 50px;
      border-radius: 10px;
      .input {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
        input {
          &::placeholder {
            color: #e0e0e0;
            font-size: 14px;
          }
        }
        .ant-input-group {
          width: 100%;
          height: 100%;
        }
        .ant-input-affix-wrapper {
          height: 50px;
          background-color: #fff;
          border: 2px solid var(--main-color);
          border-radius: 10px 0 0 10px;
        }
        .ant-input {
          padding-left: 6px;
          color: #666;
          font-size: 14px;
        }
        .ant-btn {
          width: 118px;
          height: 50px;
          background: var(--main-color);
          border-radius: 0 10px 10px 0 !important;
          span {
            letter-spacing: 1px !important;
            word-spacing: 1px !important;
          }
        }
      }
      .ant-input-search-button {
        span {
          font-size: 18px;
        }
      }
      .searchloading {
        position: absolute;
        right: -134px;
        z-index: 999;
        display: flex;
        align-items: center;
        width: calc(100% + 134px);
        height: 120px;
        padding: 0 20px;
        overflow: hidden;
        background-color: #fff;
        border: 2px solid var(--main-color);
      }
      .history-search {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 999999;
        display: flex;
        width: 822px;
        min-height: 260px;
        overflow: hidden;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        .history-search-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 306px;
          margin-right: 8px;
          padding: 0 8px 8px;
          border-right: 1px solid #e5e5e5;
          .history-search-main {
            overflow: hidden;
          }
          .left-title {
            margin: 8px 0;
            color: #333333;
            font-weight: 400;
            font-size: 12px;
          }
          .hot-search {
            margin-right: -9px;
            padding-bottom: 8px;
            color: #f28505;
            font-weight: 400;
            font-size: 12px;
            border-bottom: 1px dashed #e5e5e5;
            .hot-search-item {
              margin: 0 9px 4px 0;
            }
          }
          .sb_del {
            .icon_text {
              font-size: 12px;
            }
          }
          .sb_item {
            margin-bottom: 4px;
            color: #cccccc;
            font-weight: 400;
            font-size: 12px;
          }
        }
        .history-search-right {
          flex: 1;
          .right-title {
            margin: 8px 0;
            color: #333333;
            font-weight: 400;
            font-size: 12px;
          }
          .search-goods-item {
            position: relative;
            width: 118px;
            height: 118px;
            margin: 0 8px 8px 0;
            overflow: hidden;
            border-radius: 4px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
            .search-goods-item-tag {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              padding: 0 8px;
              color: #fff;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              background: linear-gradient(270deg, #f45f53 0%, #e64c40 100%);
              border-radius: 4px 4px 4px 0;
            }
            .search-goods-item-name {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 22px;
              padding: 0 8px;
              color: #ffffff;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              text-align: center;
              background: rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
    .focus {
      .ant-input-affix-wrapper {
        border: 2px solid var(--main-color) !important;
      }
    }
    .search-bottom {
      margin-left: 16px;
      color: #c3ced4;
      font-size: 12px;
      & > span {
        position: relative;
        &:last-child {
          &:after {
            display: none;
          }
        }
        &:hover {
          color: #333;
        }
      }
    }

    .new_home-search-left {
      width: 140px;
      height: 194px;
      .swiper-slide {
        width: 100% !important;
        height: 194px;
        overflow: hidden;
        border-radius: 4px;
        .image {
          width: 100%;
          height: 194px;
          overflow: hidden;
        }
      }
    }
  }
}

@primary-color: #f0423f;