.new_home-module1-banner {
  width: 252px;
  height: 367px;
  border-radius: 4px;
  overflow: hidden;
  .swiper-slide {
    width: 100% !important;
    height: 367px;
    overflow: hidden;
    border-radius: 4px;
    .image {
      width: 100%;
      height: 367px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .swiper-pagination{
    bottom: 6px;
  }
  .swiper-pagination-bullet{
    width: 6px;
    height: 6px;
  }
  .swiper-pagination-bullet-active{
    width: 9px;
    height: 6px;
    border-radius: 3px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    background-color: #333;
    border-radius: 50%;
    opacity: 0;
    &::after {
      color: #fff;
      font-size: 14px;
    }
  }
  .swiper-button-prev {
    left: 6px;
    &:after {
      width: 100%;
      height: 100%;
      background: url(/assets/new_imgs/swiper_left.png) no-repeat
        12px center / 8px 16px;
      content: "";
    }
  }
  .swiper-button-next {
    right: 6px;
    &:after {
      width: 100%;
      height: 100%;
      background: url(/assets/new_imgs/swiper_right.png) no-repeat
        13px center / 8px 16px;
      content: "";
    }
  }
}

@primary-color: #f0423f;