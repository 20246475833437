.PrivateHeader{
    height: 70px;
    background-color: #fff;
    .logo{
        margin-top: 11px;
    }
    .shopErWm {
        display: inline-block;
        vertical-align: text-top;
        margin-top: 0;
        height: 15px;
        margin-left: 13px;
        border-left: 1px solid #e6e6e6;
        position: relative;
        background: url(../../../assets/icon/def-code.png) no-repeat 12px 0px;
        width: 28px;
        cursor: pointer;
        .showErm {
            position: absolute;
            top: 20px;
            left: -71px;
            display: none;
            width: 170px;
            height: 198px;
            background: url(../../../assets/icon/ewm_shop.png) no-repeat;
        }
    }
    .search{
        width: 580px;
        margin-left: 280px;
        .search-input{
            border: none;
            background-color: #e8001b;
            height: 37px;
            align-items: center;
            .input{
                height: 100%;
                width: 520px;
                background-color: #fff;
                border-radius: 0;
                padding-left: 10px;
                border: 2px solid #ff414d;
            }
            .searchOur{
                width: 80px;
                height: 37px;
                border: none;
                border-radius: 0;
                background: #ff414d;
                border: 2px solid #ff414d;
                color: #fff;
            }
        }
    }
}


@primary-color: #f0423f;