.emptygoods{
    .zhaobudao {
        text-align: center;
        background-color: white;
        border: 1px solid #e4e4e4;
        padding-top: 43px;
        padding-right: 20px;
        padding-bottom: 51px;
        padding-left: 20px;
        .p1 {
            color: #424242;
            span {
                color: #f03a3c;
            }
        }
        .fuzzy-p2 {
            color: #979696;
            padding-left: 4px;
            margin-top: 8px;
        }
        .try-p3 {
            color: #979696;
            margin-top: 40px;
            line-height: 14px;
            .loc-ico {
                width: 11px;
                height: 13px;
                display: inline-block;
                background: url(../../../assets/icon/landmark.png) no-repeat;
                margin-right: 4px;
                vertical-align: bottom;
                span {
                    color: #f03a3c;
                }
            }
        }
         .totaSltation {
            display: block;
            width: 160px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #f03a3c;
            color: #fff;
            background-image: url(../../../assets/icon/searchBox-btn.png);
            background-repeat: no-repeat;
            background-position: 38px 15px;
            margin: 32px auto 0;
        }
    }
}
@primary-color: #f0423f;