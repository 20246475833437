.new_home-fix-right {
  position: fixed;
  top: 300px;
  left: calc(50% + 620px);
  width: 190px;
  height: 350px;
  margin: 8px auto;
  .swiper-slide {
    width: 100% !important;
    height: 350px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f6f6f6;
  }
}

@primary-color: #f0423f;