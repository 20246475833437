.new_home-module7 {
  .module7-right {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    .module7-right-item {
      width: 596px;
      & > .image {
        width: 596px;
        height: 240px;
        margin-bottom: 8px;
        padding: 16px;
        background-color: #fff;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .template-items {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr;
      }
      .template-item {
        overflow: hidden;
        background: #ffffff;
        border-radius: 4px;
        & > .img {
          position: relative;
          width: 193px;
          height: 180px;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &:hover {
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 9;
              display: block;
              width: 100%;
              height: 100%;
              background: #0003;
              content: "";
            }
          }
        }
      }
      .template-info {
        position: relative;
        padding: 10px 12px;
        .store-name {
          color: #333333;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        .template-name {
          color: #333;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@primary-color: #f0423f;