.copy-img-f {
  position: relative;
  margin: 0 0 10px 10px;
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 2;
  .copy-img-icon {
    position: absolute;
    right: 2px;
    bottom: 3px;
    display: none;
    width: 22px;
    height: 22px;
    color: #fff;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    text-align: center;
    background-color: #fffa;
    border-radius: 2px;
    cursor: pointer;
    img {
      margin-top: -1px;
    }
  }
  &:hover {
    .copy-img-icon {
      display: block;
    }
    .look-search-img {
      display: flex;
    }
  }

  .look-search-img {
    position: absolute;
    right: 52px;
    bottom: 3px;
    z-index: 99999999999;
    display: none;
    align-items: center;
    height: 22px;
    padding: 0 8px;
    color: #000000;
    font-weight: 400;
    font-size: 11px;
    line-height: 1;
    background-color: #fffa;
    border-radius: 2px;
    cursor: pointer;
    img {
      margin-right: 5px;
    }
  }
}

.copy-img-f-spu {
  .copy-img-icon {
    top: 95px;
  }
  .look-search-img {
    top: 95px;
  }
}

@primary-color: #f0423f;