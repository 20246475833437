.new_home-module3 {
  .module3-right {
    display: grid;
    flex: 1;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    .module3-right-item {
      width: 233px;
      height: 348px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 4px;
      .template-info {
        padding: 10px 12px;
        .store-logo {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .store-name {
          color: #333333;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        .template-name {
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@primary-color: #f0423f;