.price-content {
  padding: 10px 0 0;

  .spec-item-lists{
    max-height: 430px;
    overflow-y: auto;
  }
  .spec-item-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid transparent;

    .spec_name {
      flex: 1;
      width: 338px;
      height: 32px;
      padding: 0 14px;
      line-height: 32px;
      background: #f5f5f5;
      border-radius: 4px;
    }
    .goods_price {
      padding: 0 23px;
      color: #333333;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .spec-item-list-style {
    height: 32px;
    padding: 0 14px;
    line-height: 32px;
    background: #f5f5f5;
    border-radius: 4px;
    cursor: pointer;
  }

  .style-active {
    background: #fffafa;
    border-color: #f0423f;
  }
  .no-data,.no-data-state {
    display: none;
  }
}

@primary-color: #f0423f;