.new-home-shop_rank {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1200px;
  margin: 20px auto;
  .shop_rank_items {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
      width: 378px;
      .shop_rank_items_title {
      width: 378px;
      height: 176px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .shop_rank_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
      margin: 0 24px 0 16px;
      background: #ffffff;
      border-bottom: 1px solid #efefef;
      &:last-child {
        border-bottom: none;
      }
      .icon {
        position: relative;
        width: 34px;
        height: 34px;
        margin-right: 19px;
        img {
          width: 100%;
          height: 100%;
        }
        .icon-rank {
          position: absolute;
          top: 46%;
          left: 49%;
          z-index: 9;
          color: #ffffff;
          font-weight: 800;
          font-size: 21px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          transform: translate(-50%, -50%);
        }
      }
      .store_logo {
        width: 46px;
        height: 46px;
        margin-right: 16px;
        border-radius: 50%;
      }
      .store_name {
        color: #000000;
        font-weight: 400;
        font-size: 14px;
      }
      .count {
        color: #000000;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

@primary-color: #f0423f;