.new_home-swiper-top{
    width: 100%;
    height: 76px;
    .swiper-slide{
        width: 100% !important;
        height: 76px;
        .image{
            width: 100%;
            height: 76px;
        }
    }
}
@primary-color: #f0423f;