.stores-goods {
  width: 250px;
  margin-bottom: 10px;
  overflow: hidden;

  .store-goods-title-img {
    height: 60px;
    img {
      width: 100%;
    }
  }

  .store-goods-main {
    display: block !important;
    width: 100%;
    max-height: 325px;
    padding: 12px 12px;
    overflow-y: auto;
    background-color: #fff;
    display: inline-table;
    border-radius: 8px;
    border: 1px solid #ffe0b4;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 6px;
    }

    .store-goods-item {
      position: relative;
      margin-bottom: 15px;
      .shop_tag {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        display: flex;
        align-items: center;
        padding: 2px 6px;
        color: #fff;
        font-size: 12px;
        background: linear-gradient(143deg, #ff5100 0%, #ff850b 100%);
        border-radius: 4px;
      }
      .store-goods-img {
        position: relative;
        width: 224px;
        height: 224px;
        overflow: hidden;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .store-goods-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        padding: 0 10px;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        background-color: #0006;
      }
      .store-goods-price {
        margin-top: 2px;
        margin-bottom: 12px;
        color: var(--main-color);
        text-align: center;
        & > span {
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }
}

@primary-color: #f0423f;