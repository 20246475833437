.header1{
    height: 126px;
    background-color: #fff;
    width: 100%;
    .logo{
        img{
            object-fit: contain;
        }
    }
    .shopErWm {
        display: inline-block;
        vertical-align: text-top;
        margin-top: 0;
        height: 15px;
        margin-left: 13px;
        border-left: 1px solid #e6e6e6;
        position: relative;
        background: url(../../../assets/icon/def-code.png) no-repeat 12px 0px;
        width: 28px;
        cursor: pointer;
        .showErm {
            position: absolute;
            top: 20px;
            left: -71px;
            display: none;
            width: 170px;
            height: 198px;
            background: url(../../../assets/icon/ewm_shop.png) no-repeat;
        }
    }
    .merchand{
        position: relative;
        .merchand-info{
            height: 126px;
            &:hover{
                .specific{
                    display: block;
                }
                .merchand-open-icon{
                    background: url('../../../assets/new_header/arrow_down.png') no-repeat center/100%;
                }
                .merchand-name-copy{
                    display: inline-block;
                }
            }
        }
        .merchand-phone{
            position: relative;
            height: 126px;
            display: flex;
            align-items: center;
            &:hover{
                .specific-phone{
                    display: block;
                }
                .merchand-open-icon{
                    background: url('../../../assets/new_header/arrow_down.png') no-repeat center/100%;
                }
            }
            .specific-phone{
                display: none;
                position: absolute;
                top: 106px;
                left: -20px;
                z-index: 99;
                width: 180px;
                height: 180px;
                background: #FFFFFF;
                box-shadow: 0px 3px 7px 0px rgba(192,190,204,0.54);
                padding: 16px 25px 14px;
                text-align: center;

                p{
                    font-size: 12px;
                    // font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 7px;
                }

            }
        }
        .merchand-line{
            height: 46px;
            border-left: 1px solid #E1E1E1;
            margin: 0 20px;
        }
        .merchand-code{
            position: relative;
            width: 68px;
            height: 68px;
            padding: 2px;
            border: 1px solid #E1E1E1;
            .icon{
                position: absolute;
                top: 46%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
                border-radius: 2px;
            }
        }

        .merchand-name{
            font-size: 18px;
            // font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
            margin-bottom: 9px;
            line-height: 1;
            position: relative;
            display: flex;
            align-items: center;
            .merchand-name-copy{
                display: none;
                margin-left: 2px;
                cursor: pointer;
                color: #448ef7;
            }
        }
        ._999{
            line-height: 1;
            margin-bottom: 14px;
        }
        .merchand-source{
            font-size: 18px;
            // font-family: Source Han Sans CN;
            font-weight: 400;
            color: var(--main-color);
            line-height: 1;
        }
        .merchand-open-icon{
            margin-left: 12px;
            width: 13px;
            height: 13px;
            background: url('../../../assets/new_header/arrow_up.png') no-repeat center/100%;
        }

        .pointer .ant-image{
            .ant-image-img{
                width: 46px;
                height: 46px;
                border-radius: 50%;
                border: 1px solid #e4e4e4;
            }
        }
        .span1{
            background-color: #fbde2d;
            height: 20px;
            line-height: 20px;
            padding: 0 6px;
            border-radius: 1px;
        }
        .span2{
            background-color: #fc626a;
            height: 20px;
            line-height: 20px;
            padding: 0 6px;
            border-radius: 1px;
        }
        .collect{
            height: 20px;
            line-height: 18px;
            padding: 0 12px;
            border-radius: 4px;
            border: 1px solid #e4e4e4;
            vertical-align: middle;
            &:hover{
                color: #e5272b;
                border: 1px solid #e5272b;
            }
        }
        .collect1{
            background: #ffedee;
            color: #ff414d;
            border: 1px solid #ff414d;
        }
        .specific {
            display: none;
            position: absolute;
            top: 106px;
            left: 20px;
            z-index: 99;
            width: 500px;
            min-height: 440px;
            background: #FFFFFF;
            box-shadow: 0px 3px 7px 0px rgba(192,190,204,0.54);
            .specific-header{
                width: 100%;
                height: 79px;
                background: linear-gradient(137deg, #0F0D1D 0%, #33162B 100%);
                padding: 16px 30px 15px 30px;
            }
            .specific-avater{
                background: #636363;
                border-radius: 50%;
                margin-right: 19px;
            }
            .merchand-name{
                font-size: 16px;
                // font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
            .specific-content{
                padding: 0 30px;
                .specific-line{
                    height: 12px;
                    border-left: 1px solid #E1E1E1;
                    margin: 0 40px;
                }
                .specific-content-top{
                    padding: 20px 0 19px;
                    border-bottom: 1px dashed #E1E1E1;
                }
                .specific-content-title{
                    font-size: 14px;
                    // font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 8px;
                    line-height: 1;
                }
                .merchand-source{
                    margin: 0;
                    font-size: 14px;
                    // font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: var(--main-color);
                    margin-left: 19px;
                }
                .specific-content-conter{
                    padding: 13px 0 19px;
                    font-size: 12px;
                    // font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    .item{
                        margin-bottom: 10px;
                        line-height: 1;
                    }
                }
                .i1{
                    width: 28px;
                    height: 16px;
                    background: url(../../../assets/new_header/mem1.png)no-repeat center/100%;
                    margin-right: 12px;
                }
                .i2{
                    width: 28px;
                    height: 16px;
                    background: url(../../../assets/new_header/mem2.png)no-repeat center/100%;
                }
                .shopMegRow {
                    overflow: hidden;
                    margin-bottom: 9px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .i1{
                        width: 24px;
                        height: 20px;
                        background: url(../../../assets/icon/mem1.png)no-repeat 0 0;
                        background-size: 100%;
                    }
                    .i2{
                        width: 24px;
                        height: 20px;
                        background: url(../../../assets/icon/mem2.png)no-repeat 0 0;
                        background-size: 100%;
                        margin-top: 0.1px;
                        margin-left: 4px;
                    }
              
                    p{
                        font-size: 12px;
                        // font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                        margin-top: 7;
                        text-align: center;
                    }
                }
            }
            .specific-bottom{
                padding: 20px 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px dashed #E1E1E1;
                .button{
                    width: 120px;
                    height: 40px;
                    background: #FFEFEF;
                    border: 1px solid #E5272B;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    // font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #E5272B;
                    cursor: pointer;
                }
                .button1{
                    color: #fff;
                    background-color: #E5272B;
                }
            }

            
            .necessary-ifo {
                border-bottom: 1px solid #e6e6e6;
                color: #666;
                padding: 7px 0;
                 .necIfoLt {
                    float: left;
                    width: 230px;
                }
                .shopMegRow {
                    overflow: hidden;
                    margin-bottom: 9px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .i1{
                        width: 24px;
                        height: 20px;
                        background: url(../../../assets/icon/mem1.png)no-repeat 0 0;
                        background-size: 100%;
                    }
                    .i2{
                        width: 24px;
                        height: 20px;
                        background: url(../../../assets/icon/mem2.png)no-repeat 0 0;
                        background-size: 100%;
                        margin-top: 0.1px;
                        margin-left: 4px;
                    }
                    .img{
                        position: relative;
                        .icon{
                            position: absolute;
                            top: calc(50% - 10px);
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                .necIfoRi {
                    float: left;
                }
                p {
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    margin: 7px 0;
                }
                span {
                    margin-left: 2px;
                }
                i {
                    color: #707070;
                }
                a {
                    margin-left: 4px;
                }
                .colSiRed {
                    color: #FF414D;
                }
            }
            .storeErWm {
                padding-top: 14px;
                border-bottom: 1px solid #e6e6e6;
                .stoErLf {
                    float: left;
                    width: 160px;
                    height: 216px;
                    img {
                        width: 160px;
                        height: 160px;
                    }
                    .tisIs {
                        color: #666;
                        text-align: center;
                        margin-top: 8px;
                    }
                }
                .stoErRi {
                    float: left;
                    margin-left: 36px;
                    color: #858383;
                    width: 190px;
                    margin-top: 45px;
                    .titBg {
                        width: 13px;
                        height: 13px;
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: text-bottom;
                        position: relative;
                        background: url(../../../assets/icon/whTitleBg.png) no-repeat;
                        &:hover{
                            .topBox{
                                display: block;
                            }
                        }
                        .topBox {
                            position: absolute;
                            bottom: 16px;
                            left: -108px;
                            width: 210px;
                            height: 68px;
                            padding: 16px 15px 0;
                            z-index: 999;
                            color: #525252;
                            display: none;
                            background: url(../../../assets/icon/titBoxBg1.png) no-repeat;
                            background-size: 100%;
                        }
                    }
                    .rg-in {
                        float: left;
                        color: #666;
                        margin-top: 10px;
                        .tme-lst {
                            float: left;
                            width: 61px;
                            p {
                                margin-bottom: 8px;
                                &:last-child{
                                    text-align: center;
                                }
                            }
                        }
                        .cloGreen {
                            color: #2b9f67;
                        }
                    }
                }
            }
        }
    }
    .tips{
        height: 100%;
        padding: 10px 0;
        margin-top: 6px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-weight: 700;
        .tip{
            padding-left: 18px;
            margin-left: 20px;
            height: 20px;
            line-height: 20px;
        }
        .tip1{
            background: url(../../../assets//icon/topIco1.png) no-repeat 1px center;
        }
        .tip2{
            background: url(../../../assets//icon/topIco2.png) no-repeat 1px center;
        }
        .tip3{
            background: url(../../../assets//icon/topIco3.png) no-repeat 1px center;
        }
        .tip4{
            background: url(../../../assets//icon/topIco4.png) no-repeat 1px center;
        }
    }

    .searchd{
        margin: 0;
        margin-right: 60px;
    }

    .app-code{
        width: 100px;
        height: 93px;
        background: #F6F6F6;
        border-radius: 10px;
        padding: 8px 20px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        img{
            width: 60px;
            height: 60px;
            margin-bottom: 2px;
        }
    }
}


@primary-color: #f0423f;