.nav-new {
  width: 100%;
  height: 36px;
  font-size: 12px;
  line-height: 36px;
  // background-color: #333333;
  background-color: #f5f5f5;
  // color: #fff;
  .mobile {
    .font {
      margin-left: 2px;
    }
    &:hover {
      .font {
        color: var(--main-color);
      }
    }
  }

  .nav-item {
    position: relative;
    .more-down {
      position: absolute;
      top: 36px;
      left: -10px;
      z-index: 1000;
      display: none;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 0px rgba(0, 1, 41, 0.1);
      li {
        width: 84px;
        height: 36px;
        text-align: center;
        &:hover {
          background: var(--main-bg-color);
        }
      }
    }
    .mobileDown {
      position: absolute;
      top: 36px;
      right: -10px;
      z-index: 1000;
      display: none;
      padding: 10px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 0px rgba(0, 1, 41, 0.1);
      div {
        line-height: 1.6;
      }
    }
    .mobileDown1 {
      position: absolute;
      top: 36px;
      right: -24px;
      z-index: 1000;
      display: none;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #aaa;
      border-radius: 4px;
      &::after {
        position: absolute;
        top: -6px;
        right: 52px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-top: 1px solid #aaa;
        border-left: 1px solid #aaa;
        border-radius: 3px 0 0 0;
        transform: rotate(45deg);
        content: "";
      }
    }
    &:hover {
      .more-down {
        display: block;
      }
      .mobileDown {
        display: flex;
      }
      .mobileDown1 {
        display: block !important;
      }
    }
  }
}

@primary-color: #f0423f;