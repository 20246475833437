.merchant {
  background-color: #f1f1f1;
  .pp-narBar-Wr {
    height: 31px;
    background: #333333;
    #nav {
      position: relative;
      z-index: 5;
      line-height: 31px;
      .page_item {
        &:hover {
          .children {
            height: 400px;
            transition: all 0.25s linear;
          }
        }
      }
      .children {
        position: absolute;
        top: 31px;
        left: -10px;
        width: 154px;
        height: 0;
        overflow: auto;
        background-color: #fff;
        box-shadow: 0 0 6px 0 #cdcdcd;
        transition: all 0.25s linear;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          background: red;
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          background: #ededed;
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        }
        li {
          width: 100%;
          padding: 0 10px;
          font-size: 12px;
          &:hover {
            color: var(--main-color);
            background-color: #f8f8f8;
          }
        }
        .active {
          color: var(--main-color);
          background-color: #f8f8f8;
        }
      }
      li {
        position: relative;
        z-index: 20;
        float: left;
      }
      .current_page_item {
        margin-right: 60px;
        a {
          color: #fffefe;
          font-size: 14px;
        }
      }
      a {
        position: relative;
        z-index: 35;
        display: block;
        float: left;
        height: 31px;
        line-height: 31px;
        text-align: center;
        text-decoration: none;
        outline: none;
      }
    }
    .navbarDropDown {
      display: inline-block;
      width: 9px;
      height: 6px;
      margin-left: 7px;
      vertical-align: middle;
      background: url(../../assets/icon/navbarDropDown.png) no-repeat;
    }
    .messTz {
      float: right;
      padding-left: 24px;
      color: #fff;
      font-size: 14px;
      line-height: 31px;
      background: url(../../assets/icon/trumpet.png) no-repeat left;
    }
  }
  .newShopImg {
    width: 100%;
    min-width: 1400px;
    height: 250px;
  }
  .introduce-title {
    width: 240px;
    margin: 20px auto;
    margin-bottom: 15px;
    overflow: hidden;
    font-size: 12px;
    .line-m1 {
      float: left;
      width: 44px;
      height: 1px;
      margin-top: 22px;
      background: #c1c1c1;
    }
    .total-class {
      float: left;
      min-width: 109px;
      text-align: center;
      .totalP1 {
        color: #bcbcbc;
        font-size: 12px;
      }
    }
    .line-m2 {
      float: left;
      width: 44px;
      height: 1px;
      margin-top: 22px;
      background: #c1c1c1;
    }
  }
  .shops {
    min-height: 60px;
    .shop {
      position: relative;
      position: relative;
      width: calc(25% - 8px);
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #e4e4e4;

      &:nth-child(4n) {
        margin-right: 0;
      }
      .shop_tag_sole {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9;
        padding: 2px 6px;
        color: #fff;
        font-size: 12px;
        background: linear-gradient(90deg, #7748ff, #ed74dd);
        border-radius: 4px;
      }
      .check {
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 999;
        .ant-checkbox-inner {
          transform: scale(1.5);
        }
      }
      &:hover {
        border: 1px solid #e5272b;
      }
      .pic {
        width: 100%;
        height: 266px;
        overflow: hidden;
        .ant-image {
          width: 100% !important;
          height: 100%;
        }
      }
      .desc {
        padding: 12px 10px;
        color: #353535;
        font-size: 14px;
        background-color: #fff;
        .rowPri {
          .price {
            margin: 6px 0;
            color: var(--main-color-hign);
            font-weight: 400;
            font-size: 20px;
            // font-family: -webkit-pictograph;
            font-family: Verdana;
          }
        }
        .dsrs1 {
          min-height: 44px;
          &:hover {
            color: #666;
          }
        }
        .dsrs {
          min-height: 38px;
          &:hover {
            color: #666;
          }
        }

        .rowTitle {
          display: -webkit-box;
          height: 35px;
          margin-top: 2px;
          overflow: hidden;
          font-size: 12px;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .medalCom {
          display: inline-block;
          width: 20px;
          height: 19px;
          margin-right: 3px;
          line-height: 19px;
          text-align: center;
          vertical-align: middle;
          border-radius: 2px;
        }
        .tuihuo {
          color: #fff;
          background: #48ae77;
        }
      }
      .arr-com {
        display: inline-block;
        margin-right: 3px;
        padding: 0 4px;
        color: #2998ff;
        font-size: 12px;
        vertical-align: top;
        border: 1px solid #2998ff;
        border-radius: 4px;
      }
      .arr-com1 {
        color: #f0423f;
        border: 1px solid #f0423f;
      }
      .arr-com2 {
        color: #fa8c16;
        border: 1px solid #fa8c16;
      }
      .arr-com3 {
        color: #7cb305;
        border: 1px solid #7cb305;
      }
      .arr-com4 {
        color: #666;
        border: 1px solid #666;
      }
      .arr-com5 {
        color: #ef53c1;
        border: 1px solid #ef53c1;
      }
    }
  }
  .shopsd {
    .shop {
      width: calc(25% - 8px) !important;
      margin-right: 10px !important;
      // width: 266px !important;
      overflow: hidden;
      &:nth-child(5n) {
        margin-right: 10 !important;
      }
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
      // .pic{
      //     width: 100% !important;
      // }
      // .ant-image{
      //     width: 100% !important;
      // }
    }
  }
  .shops1 {
    min-height: 60px;
    .shop {
      position: relative;
      position: relative;
      width: 267px;
      // height: 408px;
      margin-right: 16px;
      margin-bottom: 14px;
      border: 1px solid #e4e4e4;
      &:nth-child(5n) {
        margin-right: 0;
      }

      .check {
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 999;
        .ant-checkbox-inner {
          transform: scale(1.5);
        }
      }
      &:hover {
        border: 1px solid #e5272b;
      }
      .pic {
        width: 100%;
        height: 266px;
        overflow: hidden;
        .ant-image {
          width: 100%;
          height: 100%;
          .ant-image-img {
            width: auto;
            height: 100%;
          }
        }
      }
      .desc {
        padding: 8px 10px 12px;
        color: #353535;
        font-size: 14px;
        background-color: #fff;
        .images {
          .imgd {
            display: inline-block;
            width: 34px;
            height: 34px;
            padding: 1px;
            border: 1px solid rgb(228, 228, 228);
          }
        }
        .rowTitle {
          display: -webkit-box;
          height: 35px;
          margin-top: 2px;
          overflow: hidden;
          font-size: 12px;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .rowPri {
          .price {
            margin: 6px 0;
            color: #f03a3c;
            font-size: 20px;
            // font-weight: bold;
            // font-family: -webkit-pictograph;
            font-family: Verdana;
          }
        }
        .medalCom {
          display: inline-block;
          width: 20px;
          height: 19px;
          margin-right: 3px;
          line-height: 19px;
          text-align: center;
          vertical-align: middle;
          border-radius: 2px;
        }
        .tuihuo {
          color: #fff;
          background: #48ae77;
        }
        .dsrs {
          min-height: 38px;
        }
      }
      .arr-com {
        display: inline-block;
        margin-right: 3px;
        padding: 0 4px;
        color: #2998ff;
        font-size: 12px;
        vertical-align: top;
        border: 1px solid #2998ff;
        border-radius: 4px;
      }
      .arr-com1 {
        color: #f0423f;
        border: 1px solid #f0423f;
      }
      .arr-com2 {
        color: #fa8c16;
        border: 1px solid #fa8c16;
      }
      .arr-com3 {
        color: #7cb305;
        border: 1px solid #7cb305;
      }
      .arr-com4 {
        color: #666;
        border: 1px solid #666;
      }
    }
  }
  .shops2 {
    padding: 12px;
    border: 1px solid #e4e4e4;
    .shop {
      position: relative;
      margin-right: 12px;
      .check {
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 999;
        .ant-checkbox-inner {
          transform: scale(1.5);
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .ant-image {
        display: block;
      }
      .pointer {
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 28px;
          padding-left: 12px;
          color: #fff;
          line-height: 28px;
          text-align: center;
          background: url(../../assets/icon/hot2.png) no-repeat 0 1px/100%;
          background-color: var(--main-color);
          background-size: 24px;
          border-radius: 0 0 10px 0;
          content: "爆款";
        }
      }
      .is_main {
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 28px;
          padding-left: 11px;
          color: #fff;
          font-size: 12px;
          line-height: 28px;
          text-align: center;
          background: url(../../assets/icon/zan2.png) no-repeat 1px 4px / 17px;
          background-color: var(--main-color);
          border-radius: 0 0 10px 0;
          content: "主推款";
        }
      }
    }
  }
  .shops2.swiper-container .swiper-wrapper {
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .imgs {
    display: flex;
    height: 200px;
    margin-top: 12px;
    img {
      width: 100%;
      height: 100%;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
    .imgs_default {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1400px;
      height: 100%;
      padding: 0 40px;
      background-color: #fff;
      border: 1px solid #e4e4e4;
      .img {
        width: 140px;
        height: 140px;
        object-fit: cover;
      }
      .info {
        font-weight: 700;
        font-size: 30px;
      }
      .code {
        position: relative;
        padding: 4px;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        .icon {
          position: absolute;
          top: calc(50%);
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          padding: 1px;
          background-color: #fff;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
.brandCrumb {
  margin: 18px 0 12px;
}

.ip-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  padding: 100px;
  color: #fff;
  font-size: 40px;
  text-align: center;
  background-color: #000;
  .ip-form-modal {
    background-color: #fffb;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    overflow: hidden;
    min-height: 400px;
    padding: 40px;
    color: #111;
  }
}

@primary-color: #f0423f;