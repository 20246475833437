.searchTabStore{
    margin: 12px 0 104px;
    width: 100%;
    .sort-inner{
        height: 46px;
        background-color: white;
        border: 1px solid #e4e4e4;
        .sorts{
            float: left;
            font-size: 12px;
            li {
                width: 80px;
                border-right: 1px solid #e4e4e4;
                line-height: 46px;
                text-align: center;
                font-size: 12px;
            }
            span {
                width: 8px;
                margin-top: 2px;
                height: 11px;
                display: inline-block;
                margin-left: 5px;
                background: url(../../../assets/icon/topArrowGy.png) no-repeat;
            }
            .lowSort {
                background: url(../../../assets/icon/lowArrowGy.png) no-repeat;
            }
            .upSort{
                background: url(../../../assets/icon/topArrowGy.png) no-repeat;
            }
            .choRed {
                height: 44px;
                line-height: 44px;
                background: var(--main-color);
                color: white;
                span{
                    width: 8px;
                    margin-top: 2px;
                    height: 11px;
                    display: inline-block;
                    margin-left: 5px;
                    background: url(../../../assets/icon/topArrowGy.png) no-repeat 0 0;
                }
                .lowSort {
                    background: url(../../../assets/icon/lowArrow.png) no-repeat;
                }
                .upSort{
                    background: url(../../../assets/icon/topArrow.png) no-repeat;
                }
            }
            .inputSep {
                border-right: none;
                width: 190px;
                // margin-left: 13px;
                line-height: 34px;
                position: relative;
                // border-left: 1px solid #ECECEC;
                &:hover{
                    .priceRange{
                        display: block;
                    }
                }
                .inputRge {
                    position: absolute;
                    top: 12px;
                    left: 20px;
                    z-index: 8;
                    display: flex;
                    align-items: center;
                }
                input {
                    width: 65px;
                    height: 22px;
                    line-height: 22px;
                    background: #f9f9f9;
                    color: #565656;
                    font-size: 12px;
                    padding: 0 7px;
                    border: 1px solid #e2e2e2;
                }
                i {
                    width: 8px;
                    height: 1px;
                    margin: 0 3px;
                    margin-top: -2px;
                    display: inline-block;
                    background: #646464;
                }
                .priceRange {
                    width: 286px;
                    height: 46px;
                    display: none;
                    background: #ffffff;
                    position: absolute;
                    top: -1px;
                    left: 0;
                    border: 1px solid #cdcdcd;
                    z-index: 7;
                    right: -56px;
                    box-shadow: 0 2px 5px #cdcdcd;
                    p {
                        background-color: var(--main-color);
                        border-radius: 3px;
                        color: #fff;
                        width: 46px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        margin-top: 11px;
                        margin-right: 9px;
                        float: right;
                        cursor: pointer;
                    }
                    div {
                        border: 1px solid #e4e4e4;
                        border-radius: 3px;
                        color: #111;
                        width: 46px;
                        height: 25px;
                        line-height: 24px;
                        text-align: center;
                        margin-top: 11px;
                        margin-right: 9px;
                        float: right;
                        cursor: pointer;
                    }
                }
            }

        }
        .pager {
            float: right;
            text-align: center;
            border-left: 1px solid #ECECEC;
            height: 100%;
            padding: 0 16px;
        }
        li{
            float: left;
            height: 46px;
        }

        .search-store-input{
            position: relative;
            .search-input-icon{
                position: absolute;
                top: 6px;
                right: 70px;
                width: 20px;
                height: 20px;
                line-height: 22px;
                text-align: center;
                z-index: 99;
                cursor: pointer;
            }
        }
    }
}
@primary-color: #f0423f;