.new_home-bottom {
  margin-top: 30px;
  padding-bottom: 120px;
  background-color: #f4f4f4;
  .store {
    .infos {
      padding-bottom: 20px;
      border-bottom: 1px solid #cccccc90;
    }
  }
  .swiper {
    height: 44px;
    .title {
      width: 100px;
      line-height: 44px;
      text-align: center;
    }
  }
  .intro {
    padding: 30px 0;
    border-bottom: 1px solid #cccccc90;
    .erw {
      width: 462px;
      text-align: center;
    }
    .title {
      margin-bottom: 10px;
    }
    & > ul {
      width: 680px;
    }
  }
  .introd {
    padding-top: 30px;
    font-size: 12px;
    text-align: center;
    .lined {
      width: 1px;
      height: 12px;
      margin: 4px 15px;
      border-left: 2px solid #ccc;
    }
    .paytype {
      margin: 20px 0;
      .ant-image {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@primary-color: #f0423f;