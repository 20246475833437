.new_home-module4 {
  .new_home-module4-top {
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }

  .mySwiper {
    width: 948px;
    height: 532px;
    .swiper-slide {
      width: 100% !important;
      height: 532px;
      overflow: hidden;
      .mySwiper-item {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: #fff;
      }
      .box-items {
        display: grid;
        grid-gap: 8px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 512px 210px 210px;

        .box-item {
          min-height: 0;
          overflow: hidden;
          &:hover{
            transform: scale(1.05);
            transition: all .35s linear;
          }
          &:first-child{
            &:hover{
              transform: scale(1);
            }
          }

          .images {
            width: 210px;
            height: 210px;
            overflow: hidden;
            border-radius: 4px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &:first-child {
            grid-row-start: 1;
            grid-row-end: 3;
            margin-right: 8px;

            .images {
              width: 504px;
              height: 532px;
              overflow: hidden;
              border-radius: 4px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .template-name {
            margin-bottom: 4px;
            color: #333333;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
          .goods-price {
            color: var(--main-color);
            font-weight: 500;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            font-family: Arial, Helvetica, sans-serif;
            .money {
              font-size: 12px;
              font-weight: normal;
            }
          }
        }
      }
    }
    .ant-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .image {
        object-fit: cover;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background-color: #333;
      border-radius: 50%;
      opacity: 0;
      &::after {
        color: #fff;
        font-size: 14px;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_left.png) no-repeat
          13px center / 10px 20px;
        content: "";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_right.png) no-repeat
          16px center / 10px 20px;
        content: "";
      }
    }
  }

  .spots {
    margin-right: 16px;
    .spot {
      width: 204px;
      height: 52px;
      margin-bottom: 16px;
      line-height: 52px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .spot_active {
      color: #f45f53;
      background-color: #fff7f6;
      border-color: #f45f53;
    }
  }
}

@primary-color: #f0423f;