
.PrivateTop{
    height: 44px;
    line-height: 44px;
    background-color: #333333;
    .banner1{
        height: 0;
        position: absolute;
        top: 44px;
        left: 0;
        height: 0;
        z-index: 99999999;
        background-color: #fff;
        line-height: 1;
        &>ul{
            height: 0;
            transition: all 0.25s linear;
        }
    }
    .all{
        width: 160px;
        &:hover{
            .banner1>ul{
                height: 521px;
                transition: all 0.25s linear;
            }
        }
    }
    .others{
        flex: 1;
        padding-left: 10px;
        .other{
            margin: 0 12px;
        }
    }
}
@primary-color: #f0423f;